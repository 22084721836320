import axios from 'axios';
import Swal from 'sweetalert2';

import '../defaults';
import i18n from '../../utils/i18n';
import {
  APPLICATION_BUILDER_LIST,
  APPLICATION_LIST,
  APPLICATION_BUILDER_ALL_LIST,
  APPLICATON_BUILDER_CREATE,
  APPLICATON_BUILDER_GET,
  APPLICATON_BUILDER_DELETE,
  APPLICATION_BUILDER_IS_PUBLIC_UPDATE,
  APPLICATON_BUILDER_UPDATE,
  AI_FRAMEWORK_LIST,
  APPLICATION_BUILDER_STATUS_UPDATE,
  APPLICATON_BUILDER_CATEGORY_LIST,
  INPUT_OUTPUT_TYPE_LIST,
  APPLICATON_BUILDER_SCHEMA_GET,
  APPLICATON_BUILDER_SCHEMA_CREATE,
  APPLICATON_BUILDER_SCHEMA_DELETE,
  APPLICATON_BUILDER_CATEGORY_CREATE,
  APPLICATION_BUILDER_CATEGORY_DELETE,
  APPLICATON_BUILDER_LIST_BY_ACCOUNT,
  APPLICATION_BUILDER_DESCRIPTION_UPDATE,
  APPLICATION_BUILDER_CONFIGURATION_UPDATE,
  CONFIGURATION_INPUT_CREATE,
  CONFIGURATION_INPUT_LIST,
  CONFIGURATION_INPUT_DELETE,
  WEBFLOW_PUBLISH,
  APPLICATION_BUILDER_SERVICE_STATUS_GET,
  EXAMPLE_TERMS,
  SEARCH_DATABASE_UPDATE,
  CATEGORY_CREATE,
  CHAT_START,
  SAFETENSOR_MODEL_CREATE,
  PROMPT_LIST,
  PROMPT_CREATE,
  PROMPT_DELETE,
  CIVIT_AI_DUBLICATE_MODEL,
  CREATE_FAVORITE,
  DELETE_FAVORITE,
  LIST_FAVORITE,
  GET_FAVORITE,
  TOOL_GET,
  MAKE_PUBLIC_FAVORITE,
  LIST_PUBLIC_FAVORITE,
  CHAT_LIST,
  CHAT_GET,
  CHAT_STATUS_GET,
  CHAT_MESSAGE_LIKE,
  GET_TOTAL_TASK,
  PUBLIC_GALLERY,
  PUBLIC_ITEM,
  PUBLIC_CREATE,
  PUBLIC_GALLERY_SEARCH,
  CHAT_BACKGROUND_MESSAGE,
  CHAT_ANONYM_START,
  QUESTION_GET,
  ADMIN_CHAT_LIST,
  CHAT_NAME_UPDATE,
  CHAT_BOOKMARK_UPDATE,
  TYPE_SELECTION_LIST,
  ADMIN_TEMPLATE_LIST,
  MASK_CREATE,
  DOWNLOAD_ZIP,
  CHAT_DELETE,
  GET_SUBSTYLES,
  REMOVE_WATERMARK
} from '../constants';
import { captureException } from '../../utils/handlers';
import { getAccessToken, getHaveAccount } from '../../utils/information';

const token = getAccessToken();

export const ChatStart = (data) => {
  let endpoint_url = token ? CHAT_START : CHAT_ANONYM_START
  
  try {
    return axios
      .post(endpoint_url, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatBackgroundMessageStart = (data) => {
  try {
    return axios
      .post(CHAT_BACKGROUND_MESSAGE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatList = (data) => {
  try {
    return axios
      .post(CHAT_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const AdminChatList = (data) => {
  try {
    return axios
      .post(ADMIN_CHAT_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const AdminTemplateList = (data) => {
  try {
    return axios
      .post(ADMIN_TEMPLATE_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatGet = (data) => {
  try {
    return axios
      .post(CHAT_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatStatusGet = (data) => {
  try {
    return axios
      .post(CHAT_STATUS_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatMessageLike = (data) => {
  try {
    return axios
      .post(CHAT_MESSAGE_LIKE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ListApplicationBuilder = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const AllListApplicationBuilder = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_ALL_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ListApplication = (data) => {
  try {
    return axios
      .post(APPLICATION_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const SafetensorModelCreate = (data) => {
  try {
    return axios
      .post(SAFETENSOR_MODEL_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderCreate = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderDelete = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_DELETE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderUpdate = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderIsPublicUpdate = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_IS_PUBLIC_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderIsStatusUpdate = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_STATUS_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderListByAccount = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_LIST_BY_ACCOUNT, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderGet = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const AiFrameworkList = (data) => {
  try {
    return axios
      .post(AI_FRAMEWORK_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const InputOutputTypeList = (data) => {
  try {
    return axios
      .post(INPUT_OUTPUT_TYPE_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderCategoryList = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_CATEGORY_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderCategoryCreate = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_CATEGORY_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderCategoryDelete = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_CATEGORY_DELETE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderSchemaGet = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_SCHEMA_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderSchemaCreate = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_SCHEMA_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderSchemaDelete = (data) => {
  try {
    return axios
      .post(APPLICATON_BUILDER_SCHEMA_DELETE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderDescriptionUpdate = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_DESCRIPTION_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ApplicationBuilderConfigurationUpdate = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_CONFIGURATION_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ConfigurationInputCreate = (data) => {
  try {
    return axios
      .post(CONFIGURATION_INPUT_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ConfigurationInputDelete = (data) => {
  try {
    return axios
      .post(CONFIGURATION_INPUT_DELETE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ConfigurationInputList = (data) => {
  try {
    return axios
      .post(CONFIGURATION_INPUT_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const PublishToWebflow = (data) => {
  try {
    return axios
      .post(WEBFLOW_PUBLISH, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ModelTest = (data) => {
  try {
    return fetch(
      'https://yjuzc76p7vts4n554tgpzzwr2m0wgbtp.lambda-url.eu-west-1.on.aws',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(data),
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return { status: 500 };
        }
      })
      .catch((error) => {
        return { status: 500 };
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ModelServisStatusGet = (data) => {
  try {
    return axios
      .post(APPLICATION_BUILDER_SERVICE_STATUS_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const GetExampleTerms = (data) => {
  try {
    return axios
      .post(EXAMPLE_TERMS, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const GetSpyTexts = (data) => {
  try {
    return axios
      .post(EXAMPLE_TERMS, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const SearchDatabaseUpdate = (data) => {
  try {
    return axios
      .post(SEARCH_DATABASE_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const CategoryCreate = (data) => {
  try {
    return axios
      .post(CATEGORY_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const PromptList = (data) => {
  try {
    return axios
      .post(PROMPT_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const CreatePrompt = (data) => {
  try {
    return axios
      .post(PROMPT_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const DeletePrompt = (data) => {
  try {
    return axios
      .post(PROMPT_DELETE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const CivitaiModelDublicate = (data) => {
  try {
    return axios
      .post(CIVIT_AI_DUBLICATE_MODEL, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const FavoriteCreate = (data) => {
  try {
    return axios
      .post(CREATE_FAVORITE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const FavoriteDelete = (data) => {
  try {
    return axios
      .post(DELETE_FAVORITE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const FavoriteList = (data) => {
  try {
    return axios
      .post(LIST_FAVORITE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (!getHaveAccount()) {
          window.location.href = '/';
        } else {
          Swal.fire({
            title: 'Oops!',
            text: i18n.t('Sorry, your request failed. Please try again later.'),
            icon: 'error',
          });
        }
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const SearchPublicGallery = (data) => {
  try {
    return axios
      .post(PUBLIC_GALLERY_SEARCH, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const GetPublicGallery = (data) => {
  try {
    return axios
      .post(PUBLIC_GALLERY, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const GetPublicItem = (data) => {
  try {
    return axios
      .post(PUBLIC_ITEM, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const CreatePublicItem = (data) => {
  try {
    return axios
      .post(PUBLIC_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const FavoriteGet = (data) => {
  try {
    return axios
      .post(GET_FAVORITE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const MakePublicFavorite = (data) => {
  try {
    return axios
      .post(MAKE_PUBLIC_FAVORITE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ListPublicFavorite = (data) => {
  try {
    return axios
      .post(LIST_PUBLIC_FAVORITE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ToolGet = (data) => {
  try {
    return axios
      .post(TOOL_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const GetTotalTask = (data) => {
  try {
    return axios
      .post(GET_TOTAL_TASK, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const QuestsionGet = (data) => {
  try {
    return axios
      .post(QUESTION_GET, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatNameUpdate = (data) => {
  try {
    return axios
      .post(CHAT_NAME_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ChatBookmarkUpdate = (data) => {
  try {
    return axios
      .post(CHAT_BOOKMARK_UPDATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const ListTypeSelection = (data) => {
  try {
    return axios
      .post(TYPE_SELECTION_LIST, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const MaskCreateRequest = (data) => {
  try {
    return axios
      .post(MASK_CREATE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Oops!',
          text: i18n.t('Sorry, your request failed. Please try again later.'),
          icon: 'error',
        });
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const DownloadImagesAsZip = (data) => {
  try {
    return axios
      .post(DOWNLOAD_ZIP, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const DeleteChat = (data) => {
  try {
    return axios
      .post(CHAT_DELETE, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const GetSubstyles = (data) => {
  try {
    return axios
      .post(GET_SUBSTYLES, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};

export const removeWatermark = (data) => {
  try {
    return axios
      .post(REMOVE_WATERMARK, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
      });
  } catch (error) {
    captureException(error, data);
  }
};